import React from "react";
import * as styles from "./latestNews.module.scss"
import {Col, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {Post, PostCard} from "../postsList/postsList";

export default function LatestNews(props: LatestNewsProps) {
    const {allMarkdownRemark} = useStaticQuery(graphql`
    query {
        allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/(blog)/"}}
        sort: {fields: frontmatter___date, order: DESC}
        limit: 3
    ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        ttr
                        date(formatString: "MMM DD, YYYY")
                        cover {
                            childImageSharp {
                                gatsbyImageData(quality: 70, layout: CONSTRAINED, width: 397, height: 280)
                            }
                        }
                    }
                }
            }
        }
    }`);

    const posts: Post[] = allMarkdownRemark.edges.sort((a: Post, b: Post) => {
        return new Date(b.node.frontmatter.date).getTime() - new Date(a.node.frontmatter.date).getTime();
    });


    return <>
        {posts.length !== 0 && <div className={styles.newsSectionWrapper}>
            <div className={styles.header}>{props.title}</div>
            {props.text && <div className={styles.text}>{props.text}</div>}
            <Row className={styles.newsWrapper}>
                {posts.map((data, idx) => {
                    return <Col key={idx} xs={{span: 12}} md={{span: 6}} xl={{span: 4}} className={styles.cardCol}>
                        <PostCard image={data.node.frontmatter.cover.childImageSharp.gatsbyImageData}
                                  title={data.node.frontmatter.title}
                                  timeToRead={data.node.frontmatter.ttr}
                                  date={data.node.frontmatter.date}/>
                    </Col>
                })}
            </Row>
        </div>}
    </>
}

interface LatestNewsProps {
    title: string;
    text?:string;
}
