import React from "react"
import {Col, Row} from "react-bootstrap";
import * as styles from "./articleInfo.module.scss";

export default function ArticleInfo(props: ArticleInfoProps) {

    return <Row className={styles.infoWrapper}>
        <Col xs={{span: 12}} md={{span: 6}}>
            <div className={styles.title}>Category:</div>
            <div className={styles.items}>
                {props.categories?.map((category, idx) => {
                        return <div className={styles.item} key={idx}>{category}</div>
                    })}
            </div>
        </Col>
        <Col xs={{span: 12}} md={{span: 6}}>
            <div className={styles.title}>Tags:</div>
            <div className={styles.items}>
                {props.tags?.map((tag, idx) => {
                    return <div key={idx} className={styles.item}>{tag}</div>
                })}
            </div>
        </Col>
    </Row>

}

interface ArticleInfoProps {
    categories: string[],
    tags: string[]
}
