import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import * as styles from "./articleNavigation.module.scss";
import RightArrowSVG from "../../svg/redRightArrow.svg";
import {Col, Row} from "react-bootstrap";
import moment from "moment/moment";

export default function ArticleNavigation(props: ArticleNavigationProps) {
    const {allMarkdownRemark} = useStaticQuery(graphql`
           query {
          allMarkdownRemark(
            filter: { fileAbsolutePath:{regex:"/(blog)/"} }
            sort: { fields: frontmatter___date, order: DESC }
          ) {
            edges {
              node {
                frontmatter {
                  title
                  date
                }
              }
            }
          }
        }
    `);

    const currentPost = allMarkdownRemark.edges.find(x =>
        x.node.frontmatter.title === props.articleTitle && x.node.frontmatter.date === moment(new Date(props.articleDate)).format("YYYY-MM-DD"));
    const currentPostIndex = allMarkdownRemark.edges.indexOf(currentPost);

    const nextArticle = currentPostIndex === allMarkdownRemark.edges.length - 1 ? null
        : allMarkdownRemark.edges[currentPostIndex + 1]?.node.frontmatter;

    const previousArticle = currentPostIndex === 0 ? null
        : allMarkdownRemark.edges[currentPostIndex - 1]?.node.frontmatter;

    const getNextArticleLink = () => {
        return `/insights/${moment(new Date(nextArticle.date)).format("DD-MM-YYYY")}-${nextArticle.title.toLowerCase().substring(0, 50).trim()
            .replaceAll(" ", "-")
            .replaceAll(/[^-A-Za-z\d]/g, "")}`;
    }

    const getPreviousArticleLink = () => {
        return `/insights/${moment(new Date(previousArticle.date)).format("DD-MM-YYYY")}-${previousArticle.title.toLowerCase().substring(0, 50).trim()
            .replaceAll(" ", "-")
            .replaceAll(/[^-A-Za-z\d]/g, "")}`;
    }

    return <>
        {allMarkdownRemark.edges.length ? <Row className={styles.articleNavigationContainer}>
            <div className={styles.horizontalLineUpper}/>
            {nextArticle ? <>
                <Col lg={!previousArticle ? 12 : 6} md={12}
                     className={previousArticle ? styles.navigation : styles.oneArticleNavigation}>
                    <Link to={getNextArticleLink()}>
                        <div className={styles.previousDirection}>Previous</div>
                        <div className={styles.previousArticleTitle}>
                            {nextArticle.title.length > 40
                                ? `${nextArticle.title.substring(0, 40).trim()} (...)`
                                : nextArticle.title}
                        </div>
                        <RightArrowSVG className={styles.leftArrow} style={{transform: 'rotate(180deg)'}}/>
                    </Link>
                </Col>
            </> : null}
            {previousArticle ? <Col lg={!nextArticle ? 12 : 6} md={12}
                                    className={nextArticle ? styles.navigation : styles.oneArticleNavigation}>
                {nextArticle ? <div className={styles.verticalLine}/> : null}
                <Link to={getPreviousArticleLink()}>
                    <div className={styles.nextDirection}>Next</div>
                    <div className={styles.nextArticleTitle}>
                        {previousArticle.title.length > 40
                            ? `${previousArticle.title.substring(0, 40).trim()} (...)`
                            : previousArticle.title}
                    </div>
                    <RightArrowSVG className={styles.rightArrow}/>
                </Link>
            </Col> : null}
            <div className={styles.horizontalLine} style={{marginBottom: '100px'}}/>
        </Row> : null}
    </>
}

interface ArticleNavigationProps {
    articleTitle: string,
    articleDate: string;
}
