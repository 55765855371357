import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {Container, ThemeProvider} from "react-bootstrap";
import * as styles from "./postDetails.module.scss";
import FacebookSVG from "../../svg/socialMediaSvg/facebook.svg";
import LinkedInSVG from "../../svg/socialMediaSvg/linkedIn.svg";
import MessengerSVG from "../../svg/socialMediaSvg/messenger.svg";
import EnvelopeSVG from "../../svg/envelope.svg";
import CopySVG from "../../svg/copy.svg";
import {Helmet} from "react-helmet";
import ArticleInfo from "../../components/articleInfo/articleInfo";
import ArticleNavigation from "../../components/articleNavigation/articleNavigation";
import LatestNews from "../../components/latestNews/latestNews";

export default function PostDetails({data}: any) {
    const {date, ttr, title, cover, category, tags} = data.markdownRemark.frontmatter;

    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>{title} - Recoded Recruitment</title>
            <meta name="description"
                content={data.markdownRemark.html.replace(/<[^>]+>/g, '').slice(0, 250) + "..."}/>
        </Helmet>
        <ThemeProvider>
            <header>
                <Header/>
            </header>
            <Container fluid="xxl" style={{height: "200vh", paddingTop: 150}}>
                <main>
                    <div className={styles.postContainer}>
                        <div className={styles.intro}>
                            {`${date} — ${ttr}`}
                        </div>
                        <div className={styles.title}>
                            {title}
                        </div>
                        <GatsbyImage className={styles.image}
                                     image={cover?.childImageSharp.gatsbyImageData} alt="postImage"/>
                        <div className={styles.content} dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
                        <div className={styles.shareButtons}>
                            <div className={styles.horizontalLine} style={{marginRight: '20px'}}/>
                            <div className={styles.button} style={{marginRight: '10px'}}>
                                <FacebookSVG className={styles.svg}/>
                            </div>
                            <div className={styles.button} style={{marginRight: '10px'}}>
                                <LinkedInSVG className={styles.svg}/>
                            </div>
                            <div className={styles.button} style={{marginRight: '10px'}}>
                                <MessengerSVG className={styles.svg}/>
                            </div>
                            <div className={styles.button} style={{marginRight: '10px'}}>
                            <span className={styles.svg}>
                                <EnvelopeSVG className={styles.svg}/>
                            </span>
                            </div>
                            <div className={styles.button}>
                                <CopySVG className={styles.svg}/>
                            </div>
                            <div className={styles.horizontalLine} style={{marginLeft: '20px'}}/>
                        </div>
                        <ArticleInfo categories={category} tags={tags}/>
                        <ArticleNavigation articleTitle={title} articleDate={date}/>
                        <LatestNews title={"Latest from the blog"}/>
                    </div>
                </main>
                <footer>
                    <Footer/>
                </footer>
            </Container>
        </ThemeProvider>
    </>;
}

export const query = graphql`
    query PostDetails($title: String) {
      markdownRemark(frontmatter: {title: {eq: $title}}) {
        html
        frontmatter {
          title
          ttr
          date(formatString: "MMM DD, YYYY")
          tags
          category
          cover {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
`
