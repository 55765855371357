// extracted by mini-css-extract-plugin
export var arrowBase = "articleNavigation-module--arrowBase--5efd7";
export var articleNavigationContainer = "articleNavigation-module--articleNavigationContainer--79d0f";
export var articleTitleBase = "articleNavigation-module--articleTitleBase--ec5bd";
export var directionBase = "articleNavigation-module--directionBase--88230";
export var horizontalLine = "articleNavigation-module--horizontalLine--6675a";
export var horizontalLineUpper = "articleNavigation-module--horizontalLineUpper--43e32";
export var leftArrow = "articleNavigation-module--leftArrow--dc432";
export var mobileHorizontalLine = "articleNavigation-module--mobileHorizontalLine--56914";
export var navigation = "articleNavigation-module--navigation--4a5e0";
export var nextArticleTitle = "articleNavigation-module--nextArticleTitle--4fd0f";
export var nextDirection = "articleNavigation-module--nextDirection--9bae4";
export var oneArticleNavigation = "articleNavigation-module--oneArticleNavigation--82873";
export var previousArticleTitle = "articleNavigation-module--previousArticleTitle--334de";
export var previousDirection = "articleNavigation-module--previousDirection--8445d";
export var rightArrow = "articleNavigation-module--rightArrow--068a2";
export var verticalLine = "articleNavigation-module--verticalLine--beef1";